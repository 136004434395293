<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loader">
            <div class="how-to-use-app-category-form mt-1">
                <base-input name="name" label="Category Name*" v-model="form.name" :rules="{ custom_required: true }"
                    placeholder="Category Name">
                </base-input>
                <base-select name="status" label="Status*" v-model="form.status" :options="statusOptions"
                    :rules="{ custom_required: true }">
                </base-select>
            </div>
            <div class="text-right mb-2">
                <base-button type="gray" size="xl" class="px-2 mr-3" :disabled="loader" @click="$emit('cancel')">Cancel</base-button>
                <base-button native-type="submit" type="dark-blue" size="xl" :disabled="loader">{{ submitButton }}</base-button>
            </div>
        </form>
    </validation-observer>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';

export default {
    props: {
        howToUseAppCategory: Object,
        loader: {
            type: Boolean,
            default: false
        }
    },
    components: {
    },
    mixins: [generateErrorMessageMixin],
    data() {
        return {
            form: {
                name: '',
                status: 0,
            },
            statusOptions: [
                {
                    label: 'Active',
                    value: 1,
                },
                {
                    label: 'Inactive',
                    value: 0,
                }
            ],
        };
    },
    mounted() {
        if (this.howToUseAppCategory) {
            this.setValues(this.howToUseAppCategory);
        }
    },
    watch: {
        'howToUseAppCategory': function (newValue) {
            this.setValues(newValue);
        }
    },
    methods: {
        setValues(value) {
            this.form.name = value?.name || '';
            this.form.status = value?.status  || 0;
        },
        async onSubmit() {
            if(this.howToUseAppCategory) this.$emit('update', {fd:this.form,id:this.howToUseAppCategory.id});
            else this.$emit('add', this.form);
        },
    },
    computed:{
        submitButton(){
            return !this.howToUseAppCategory ? 'Save' : 'Update';
        }
    }
};
</script>
  
<style lang="scss">
.how-to-use-app-category-form {
    .form-group{
        margin-bottom: 0.65rem !important;
    }
    input {
        &.form-control {
            height: calc(1.5em + 1.25rem + 2px);
        }
    }
}
</style>
  