<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2 how-to-use-app-category-index">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ `List of Categories` }}</h1>
            <div>
                <base-button type="dark-blue" size="sm" class="px-4" @click="showAddEditModal">Add New</base-button>
            </div>
        </div>
        <div class="mt-4 cardStyle p-3">
            <div class="d-flex flex-wrap py-4" v-if="howToUseAppCategorySelected.length > 0">
                <base-button type="danger" outline size="xl" @click="showMultipleDelete"
                    :disabled="loaders.multiple">Delete</base-button>
                <base-button type="active-green" outline size="xl" @click="toggleStatusMultiple(true)"
                    :disabled="loaders.multiple">Activate</base-button>
                <base-button type="spruce-blue" outline size="xl" @click="toggleStatusMultiple(false)"
                    :disabled="loaders.multiple">Deactivate</base-button>
            </div>
            <el-table class="mt-4 mb-0 table-responsive table-flush" header-row-class-name="thead-light" width="100%"
                :data="howToUseAppCategory" v-loading="loaders.fetching" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="50">
                </el-table-column>
                <el-table-column label="CATEGORY NAME" prop="name">
                    <template slot-scope="scope">
                        <div class="trim-text-overflow">
                            {{ scope.row.name  }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="STATUS" prop="status" width="250" header-align="left" align="left">
                    <template slot-scope="scope">
                        <i class="fa fa-circle mr-2 "
                            :class="{ 'text-active-green': scope.row.status, 'text-silver': !scope.row.status }"
                            style="font-size: 7px;"></i>
                        <p class="text-break m-0 status d-inline-block" style="font-size: inherit;">{{ scope.row.status
                            ? "Active" :
                            "Inactive"
                        }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="ACTIONS" header-align="left" width="200">
                    <div slot-scope="{ $index, row }" class="d-flex">
                        <img src="/img/icons/buttons/edit.svg" style="cursor: pointer;" class="mr-2" alt=""
                            @click="edit(row)" />
                        <img src="/img/icons/buttons/delete.svg" style="cursor: pointer;" alt=""
                            @click="deleteHowToUseAppCategory(row)" />
                    </div>
                </el-table-column>
            </el-table>

            <div class="d-flex justify-content-end mt-4 mr-5">
                <custom-pagination class="pagination-no-border" v-model="pagination.current_page"
                    :per-page="pagination.per_page" :total="pagination.total" @input="changePage" />
            </div>

        </div>
        <div class="modals">
            <remove-modal :loader="loaders.removeModal" :modal-text="remove.modalText" :removeId="remove.id"
                @onRemove="removeHowToUseAppCategory" @onRemoveMultiple="removeHowToUseAppCategoryMultiple" />
            <add-edit-dialog :visible.sync="addEditModal" @closeDialog="closeAddEditModal"
                :how-to-use-app-category="selectedHowToUseAppCategory" @added="getHowToUseAppCategory" @updated="getHowToUseAppCategory" />
        </div>
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import { Table, TableColumn } from "element-ui";
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import RemoveModal from "@/components/Modals/RemoveModal.vue";
import AddEditDialog from "@/views/Components/SuperAdmin/HowToUseApp/Category/AddEditDialog";

export default {
    components: {
        BackButton,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CustomPagination,
        RemoveModal,
        AddEditDialog,
    },
    data() {
        return {
            loaders: {
                fetching: false,
                removeModal: false,
                multiple: false,
            },
            remove: {
                modalText: '',
                id: ''
            },
            pagination: {
                current_page: 1,
                per_page: 0,
                total: 0
            },
            activeOptions: [
                {
                    label: 'All',
                    value: ''
                },
                {
                    label: 'Active',
                    value: 1
                },
                {
                    label: 'Inactive',
                    value: 0
                },
            ],
            addEditModal: false,
            howToUseAppCategory: [],
            howToUseAppCategorySelected: [],
            selectedHowToUseAppCategory: null,
        };
    },
    mounted() {
        this.getHowToUseAppCategory();
    },
    methods: {

        edit(item) {
            this.showModal = false;
            this.selectedHowToUseAppCategory = item;
            this.showAddEditModal();
        },
        showAddEditModal() {
            this.addEditModal = true;
        },
        closeAddEditModal() {
            this.selectedHowToUseAppCategory = null;
            this.addEditModal = false;
        },
        showMultipleDelete() {
            this.$store.commit("showRemoveModal");
            this.remove.modalText = `You want to remove these selected How To Use App.`;
            this.remove.id = this.howToUseAppCategorySelected;
        },
        deleteHowToUseAppCategory(item) {
            this.$store.commit("showRemoveModal");
            this.remove.modalText = `You want to remove this Category "${item.name}".`;
            this.remove.id = item.id;
        },
        async removeHowToUseAppCategory() {
            try {
                let id = this.remove.id;
                let response = await this.$store.dispatch('HowToUseAppModule/_deleteHowToUseAppCategoryById', { id });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    message: message
                });
                this.$store.commit("hideRemoveModal");
                if (this.howToUseAppCategory.length == 1) {
                    this.pagination.current_page = 1;
                }
                this.getHowToUseAppCategory();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'How To Use App',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });
            }
        },
        async removeHowToUseAppCategoryMultiple() {
            this.loaders.removeModal = true;
            this.loaders.multiple = true;
            try {
                let id = this.remove.id;
                let response = await this.$store.dispatch('HowToUseAppModule/_deleteMultipleHowToUseAppCategory', { ids: id });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    message: message
                });
                this.$store.commit("hideRemoveModal");
                if (this.howToUseAppCategory.length == 1) {
                    this.pagination.current_page = 1;
                }
                this.getHowToUseAppCategory();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'How To Use App',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });

            }
            this.loaders.removeModal = false;
            this.loaders.multiple = false;
        },
        async toggleStatusMultiple(status) {
            this.loaders.multiple = true;
            try {
                let id = this.howToUseAppCategorySelected;
                let response = await this.$store.dispatch('HowToUseAppModule/_toggleStatusMultipleHowToUseAppCategory', { fd: { ids: id, status } });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    message: message
                });
                this.getHowToUseAppCategory();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'How To Use App',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });
            }
            this.loaders.multiple = false;
        },
        changePage() {
            this.getHowToUseAppCategory();
        },
        async getHowToUseAppCategory() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('HowToUseAppModule/_getAllHowToUseAppCategory', { params: { page: this.pagination.current_page } });
                let data = response?.data?.data;
                if (data) {
                    this.pagination.current_page = data.current_page;
                    this.pagination.per_page = data.per_page;
                    this.pagination.total = data.total;
                    this.howToUseAppCategory = data.data;
                }
            } catch (error) {
                this.pagination.current_page = 1;
                this.pagination.per_page = 0;
                this.pagination.total = 0;
                this.howToUseAppCategory = [];

            }
            this.loaders.fetching = false;
        },
        handleSelectionChange(val) {
            this.howToUseAppCategorySelected = val.map((el) => el.id);
        }

    },
};
</script>
<style lang="scss">
.how-to-use-app-category-index {
    .filters {
        .filter-search {
            max-width: 180px;

            .form-control {
                height: calc(1.5em + 1.25rem + 2px);
            }
        }
    }

    .el-table {
        .btnDraggable {
            padding-top: 0px !important;
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            cursor: grabbing;
            margin-left: 0px;
        }

        td {
            background-color: white;

            .status {
                font-size: 12px;
                font-weight: 600;
            }
        }

        .el-checkbox__input {
            &.is-checked {
                .el-checkbox__inner {
                    background-color: #FF6421 !important;
                    border-color: #FF6421 !important;
                }
            }
        }
    }
}
</style>
  